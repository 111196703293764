import React from "react";
import { Card } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import Section from "./Section";
import { graphql, StaticQuery } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import Img from "gatsby-image";
import CallToActionButton from "../components/CallToActionButton";

const Projects = () => (
  <StaticQuery
    query={graphql`
      query {
        allMarkdownRemark(
          filter: { fileAbsolutePath: { regex: "/projects.md/" } }
        ) {
          nodes {
            frontmatter {
              projects {
                imageAlt
                title
                subTitle
                actionText
                go
                image {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => {
      const { projects } = data.allMarkdownRemark.nodes[0].frontmatter;
      return (
        <div className="projects">
          <Section
            bgColor="#181b2e"
            dark
            heading={
              <blockquote>
                My electricity bill went from <span>Rs. 30,000</span> to{" "}
                <span>Rs. 0</span>
                <cite>Saad Amanullah, 10kW System</cite>
              </blockquote>
            }
          >
            <Swiper
              slidesPerView="auto"
              spaceBetween={30}
              loop={true}
              centeredSlides={true}
              loop={true}
              pagination={{
                clickable: true,
              }}
              className="list"
            >
              {projects.map((project, index) => (
                <SwiperSlide className="item" key={index}>
                  <Card>
                    <Img
                      fluid={project.image.childImageSharp.fluid}
                      alt={project.imageAlt}
                      style={{ height: "200px", width: "100%" }}
                      imgStyle={{ objectFit: "cover" }}
                    />
                    <Card.Body>
                      <div>
                        <Card.Title>{project.title}</Card.Title>
                        <Card.Subtitle>{project.subTitle}</Card.Subtitle>
                      </div>

                      <CallToActionButton
                        link={project.go}
                        text={project.actionText}
                        btnClass="btn btn-secondary"
                        size="small"
                      />
                    </Card.Body>
                  </Card>
                </SwiperSlide>
              ))}
            </Swiper>
          </Section>
          <h2></h2>
        </div>
      );
    }}
  />
);

export default Projects;
